













import { Vue, Component, Prop } from 'vue-property-decorator'
import { BaseTxAssetSummary } from '@/helpers/history_helper'
import AvaAsset from '@/js/AvaAsset'
import { bnToBig } from '@/helpers/helper'
import { BN } from '@lamina1/lamina1-js'

@Component
export default class BaseTxOutput extends Vue {
    @Prop() assetID!: string
    @Prop() summary!: BaseTxAssetSummary

    get assetDetail(): AvaAsset {
        return (
            this.$store.state.Assets.assetsDict[this.assetID] ||
            this.$store.state.Assets.nftFamsDict[this.assetID]
        )
    }

    get payload() {
        return this.summary.payload
    }

    get isProfit() {
        return this.summary.amount.gte(new BN(0))
    }

    get actionText() {
        if (this.isProfit) {
            return 'Received'
        } else {
            return 'Sent'
        }
    }

    get direction() {
        if (this.isProfit) {
            return 'from'
        } else {
            return 'to'
        }
    }
    get amtText() {
        let big = bnToBig(this.summary.amount, this.assetDetail?.denomination || 0)
        return big.toLocaleString()
    }
}
